<template>
    <parent-layout :title="`${isCreate ? 'Create' : 'Edit'} Role`">
        <extended-form
            :form-ready="form.ready"
            :form-data="form.data"
            :submit="submit"
            :disabled="!hasAccessRole || getFetching || getSaving"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <text-field :form="form" field-key="name" label="Name" required />
                    <text-field
                        :form="form"
                        field-key="role"
                        label="Role"
                        :disabled="!isCreate"
                        required
                    />
                    <drop-down
                        field-key="status"
                        :items="['active', 'restricted']"
                        :form="form"
                        item-text="text"
                        item-value="value"
                        required
                    />

                    <auto-complete
                        :form="form"
                        field-key="childRoles"
                        hide-details="auto"
                        :items="roleItems"
                        item-text="name"
                        item-value="id"
                        multiple
                        return-object
                        chips
                        dense
                        small-chips
                    />
                </v-col>
            </v-row>
        </extended-form>
    </parent-layout>
</template>

<script>
import { ROLE_USER_EDIT } from '@/helpers/security/roles'
import ExtendedForm from '@/components/form/ExtendedForm'
import { mapGetters, mapActions } from 'vuex'
import TextField from '@/components/form/TextField'
import ParentLayout from '@/components/layout/ParentLayout'
import { formSnackbar, errorSnackbar } from '@/helpers/snackbar'
import DropDown from '@/components/form/DropDown.vue'
import AutoComplete from '@/components/form/AutoComplete'

export default {
    components: {
        ParentLayout,
        TextField,
        ExtendedForm,
        DropDown,
        AutoComplete,
    },
    data: () => ({
        valid: false,
        form: {
            data: {
                name: null,
                role: null,
                status: null,
                childRoles: [],
            },
            rules: {
                name: [(v) => !!v || 'Name is required'],
                role: [
                    (v) => !!v || 'Role is required',
                    (v) =>
                        /^ROLE_[A-Z]+(_[A-Z]+)*$/.test(v) ||
                        "The value must match the regex '^ROLE_[A-Z]+$'. (ROLE_NAME_LIKE_THIS)",
                ],
                status: [(v) => !!v || 'Status is required'],
            },
            errors: [],
            ready: false,
        },
        allRoles: [],
    }),
    computed: {
        ...mapGetters({
            roleItems: 'role/getItems',
            hasRole: 'auth/hasRole',
            getFetching: 'role/getFetching',
            getSaving: 'role/getSaving',
            getItemById: 'role/getItemById',
            getDistrictId: 'getDistrictId',
        }),
        getParentRoute() {
            const parentRouteName = this.$route.meta.breadcrumb

            return this.$router.resolve({
                params: { districtId: this.getDistrictId },
                name: parentRouteName,
            })
        },
        getData() {
            return this.getItemById(this.getRouteId, 'id')
        },
        getRouteId() {
            return this.$route.params.id
        },
        hasAccessRole() {
            return this.hasRole(ROLE_USER_EDIT)
        },
        isCreate() {
            return this.$route.name === 'roles-create'
        },
    },
    async mounted() {
        //select all roles (for the child roles list and so we have the current role)
        await this.fetchRoles({})
        let formData = { ...this.form.data }

        if (!this.isCreate) {
            const role = this.getData
            if (!role) {
                const subtext = `Local role could not be found by account id ${this.getRouteId}.`
                await errorSnackbar({ subtext })

                await this.$router.push(this.getParentRoute.href)
                return
            }

            formData = Object.keys(this.form.data).reduce((acc, curr) => {
                acc[curr] = role[curr]

                return acc
            }, this.form.data)
        }

        this.form.data = formData
        this.form.ready = true
    },
    methods: {
        ...mapActions({
            fetchRoles: 'role/cget',
            patchRole: 'role/patch',
            postRole: 'role/post',
        }),
        async submit() {
            const data = { ...this.form.data }
            data.childRoles = this.form.data.childRoles.map((role) => {
                return role.id
            })

            let resp = null
            if (this.isCreate) {
                resp = await this.postRole({ data })
            } else {
                const id = this.getData.id
                resp = await this.patchRole({ id, data })
            }

            if (resp.errors) {
                this.form.errors = resp.errors
                return
            }

            const identifier = resp?.role?.name

            if (identifier) {
                formSnackbar({
                    isEdit: true,
                    type: 'Role',
                    identifier,
                })
            }

            await this.$router.push({ name: 'roles' })
        },
    },
}
</script>